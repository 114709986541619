<template>
  <v-container>
    <v-row>
      <v-spacer/>
      <v-col cols="auto">
        <v-card class="elevation-2 pa-1">
          <div
              id="itnrwMap_0"
              class="itnrwMap map"
              data-itnrw-map="width:500px, height:450px, basemap:none"
              data-itnrw-module-search="verwaltungsgebiete"
              data-itnrw-module-tools="naechsteAdresse"
              data-itnrw-callbacks="naechsteAdresse:infoClick"
          ></div>
          <div v-if="products[0]" style="width:500px">{{ products }}</div>
          <div v-if="metadata" style="width:500px; padding:10px" v-html="metadata"/>

        </v-card>
      </v-col>
      <v-col xs="0" lg="1"/>
      <v-col class="mt-1 mt-3 mx-sm-8" sm="11" lg="4">
        <v-container>
          <v-row>
            <v-container>
              <v-row>
                <div style="color:darkslategrey; font-weight: 600;font-size:12px" class="ml-2">Verfügbarkeit von
                  Vermessungsunterlagen im Portal
                </div>

              </v-row>
              <v-row>
                <v-btn-toggle
                    v-model="selectedData"
                    dense
                    :multiple=false
                >
                  <v-col dense>
                    <template v-for="button in buttonsData">
                      <v-btn
                          class="ma-1"
                          v-if="button.visibility.includes(typeOfAccess)"
                          :key="button.name"
                          :value="button.name"
                          @click="changeLayer(button)"
                      >
                        <v-icon
                            v-if="button.icon"
                            left
                            :color="button.color"
                        >
                          {{ button.icon }}
                        </v-icon>
                        {{ button.label }}
                      </v-btn>
                    </template>
                  </v-col>
                </v-btn-toggle>
              </v-row>
              <template v-if="typeOfAccess==='vermessungsstellen'">
                <v-row>
                  <div style="color:darkslategrey;font-weight: 600;font-size:12px" class="mt-2 ml-2">Funktionen</div>
                </v-row>
                <v-row>
                  <v-btn-toggle
                      v-model="selectedData"
                      dense
                      :multiple=false
                  >
                    <v-col dense>
                      <template v-for="button in buttonsFunction">
                        <v-btn
                            class="ma-1"
                            v-if="button.visibility.includes(typeOfAccess)"
                            :key="button.name"
                            :value="button.name"
                            @click="changeLayer(button)"
                        >
                          <v-icon
                              v-if="button.icon"
                              left
                              :color="button.color"
                          >
                            {{ button.icon }}
                          </v-icon>
                          {{ button.label }}
                        </v-btn>
                      </template>
                    </v-col>
                  </v-btn-toggle>
                </v-row>
              </template>
              <v-row class="pl-1">
                <v-col>
                  <div style="color:darkslategrey;font-size:12px">
                    Über Aktivierung des Werkzeugs
                    <v-list-item-avatar class="ma-0">
                      <v-img
                          :src="require('@/assets/tool_info.png')"></v-img>
                    </v-list-item-avatar>
                    und Mausklick in der linken Karte werden
                    die <b>verfügbaren Produkte <span v-if="typeOfAccess==='vermessungsstellen'">& Funktionen</span></b>
                    je Katasterbehörde angezeigt.
                    Darüber hinaus kann über die oberen <b>Schaltflächen</b> die Verfügbarkeit in der Karte
                    <span style="color:#4caf50; font-weight: bolder;font-size:12px">grün</span> dargestellt werden.
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row v-if="typeOfAccess==='opendata'">
      <v-spacer/>
      <v-col sm="11" xl="9">
        <VCardMessages class="mt-2" :typeOfAccess="typeOfAccess"/>
      </v-col>
      <v-spacer/>
    </v-row>

  </v-container>

</template>
<script>
import {
  visibility,
  buttonsData,
  buttonsFunction,
  oapi,
  mapnrw,
  API,
  infoProducts_opendata,
  infoProducts_Vermessungsstelle,
  infoFunctions_Vermessungsstelle
} from '@/config';

import VCardMessages from "@/components/VCardMessages";
import {Style, Fill, Stroke, Text} from "ol/style";
import {Vector as layer_Vector} from "ol/layer";
import {Vector} from "ol/source";
import {GeoJSON} from "ol/format";

export default {
  components: {VCardMessages},
  props: {
    messages: {
      type: Array,
      required: true,
    },
    typeOfAccess: {
      type: String,
    }
  },
  data() {
    return {
      visibility, buttonsData, buttonsFunction,
      showHint: true,
      showMessages: false,
      selectedCategory: '',
      selectedData: '',
      pattern_Wartung: {},
      pattern_Stoerung: {},

      style_Kreise: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgb(64,101,131)",
          width: 1,
        }),
        text: new Text({
          font: "bold 13px Calibri,sans-serif",
          fill: new Fill({
            color: "rgb(64,101,131)",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 3,
          })
        }),
      }),
      style_Verfuegbarkeit: new Style({
        fill: new Fill({
          color: "rgb(139,213,88)",
        }),
      }),
      // https://spatial-dev.guru/2022/03/05/openlayers-advance-style/#3-implement-fill-pattern-style-for-polygon-and-display-points-at-vertices
      style_Wartung: new Style({
        fill: new Fill(
            {
              color: this.pattern_Wartung,
            })
      }),
      style_Stoerung: new Style({
        fill: new Fill(
            {
              color: this.pattern_Stoerung,
            })
      }),
      vectorLayer_Kreise: new layer_Vector({
        source: new Vector({
          url: oapi + "?f=json&crs=http://www.opengis.net/def/crs/EPSG/0/25832&maxAllowableOffset=100",
          format: new GeoJSON(),
        }),
        style: (feature) => {
          // ToDo: geht seit 09.01.25 plötzlich nicht mehr ... ?
          // this.style_Kreise.getText().setText(feature.get("katasterbehoerden.abkuerzung"));
          this.style_Kreise.getText().setText(feature.get("katasterbehoerden").abkuerzung);
          return this.style_Kreise;
        },
        zIndex: 7000,
        name: "vup",
      }),
      vectorLayer_Verfuegbarkeit: new layer_Vector({
        source: new Vector({
          url: oapi + "?f=json&crs=http://www.opengis.net/def/crs/EPSG/0/25832&maxAllowableOffset=100" + (this.typeOfAccess === "opendata" ? "&produkte.opendata=true" : ""),
          format: new GeoJSON(),
        }),
        style: () => {
          return this.style_Verfuegbarkeit;
        },
        zIndex: 6000,
        name: "vup",
      }),
      vectorLayer_Wartung: new layer_Vector({
        source: new Vector({
          url: oapi + "?f=json&crs=http://www.opengis.net/def/crs/EPSG/0/25832&maxAllowableOffset=100&&filter=meldungen.art in ('Wartung')&filter-lang=cql2-text",
          format: new GeoJSON(),
        }),
        style: () => {
          return this.style_Wartung;
        },
        zIndex: 6000,
        name: "vup",
      }),
      vectorLayer_Stoerung: new layer_Vector({
        source: new Vector({
          url: oapi + "?f=json&crs=http://www.opengis.net/def/crs/EPSG/0/25832&maxAllowableOffset=100&&filter=meldungen.art in ('Störung')&filter-lang=cql2-text",
          format: new GeoJSON(),
        }),
        style: () => {
          return this.style_Stoerung;
        },
        zIndex: 6000,
        name: "vup",
      }),
      products: [],
      metadata: '',
      meldungen: []
    }
  },
  watch: {
    selectedCategory(n) {
      if (n !== undefined && n.length !== 0)
        this.showHint = false;
      else {
        this.showHint = true;
      }
    }
  }
  ,
  methods: {
    changeLayer(buttonSelect) {
      // nur URL-Änderung funktioniert scheinbar nicht
      // https://stackoverflow.com/questions/31991506/openlayers3-change-layer-source-url-or-replace-features-loaded-from-another-url
      let product = buttonSelect.name.replace('OpenData', '').toLowerCase();
      let newSource = new Vector({
        url: oapi + "?f=json&crs=http://www.opengis.net/def/crs/EPSG/0/25832&maxAllowableOffset=100&produkte." + product + "=true",
        format: new GeoJSON(),
      });
      this.vectorLayer_Verfuegbarkeit.setSource(newSource);
    },

    async selectMeldung(kn) {
      const rawResponse = await fetch(API.url + '/meldung/selectbykn.json', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "type": "Feature",
          "properties": {
            "kn": kn
          }
        })
      });
      const content = await rawResponse.json();
      this.meldungen = content.features;
    },

    async showMetadata(products) {
      this.products = products[0].properties;
      let props = products[0].properties;
      await this.selectMeldung(props.kn);
      let metadataHtml = "<h3>" + props.name + "</h3>";

      if (this.meldungen && this.meldungen.length > 0) {
        this.meldungen.forEach((meldung) => {
          metadataHtml += "<div style='margin:5px'>";
          metadataHtml += "<span style='color:" + (meldung.properties.art === 'Wartung' ? "blue" : "red") + "'>";
          metadataHtml += "<b>" + meldung.properties.art + ": </b>";
          metadataHtml += meldung.properties.meldungstext;
          metadataHtml += "</span></div>";
        })
      }

      metadataHtml += "<h4 style='padding-top: 10px'>Produkte Open Data</h4>"
      metadataHtml += "<div style='margin-left:10px'>"
      if (!props.opendata)
        metadataHtml += "Es liegen noch keine offenen Vermessungsunterlagen vor.";
      else
        infoProducts_opendata.forEach((x) =>
            metadataHtml +=
                "<input type='checkbox' id='" + x.id + "' onclick='return false;'" + (props[x.prop] ? "checked=''" : "") + ">" +
                " <label for='" + x.id + "'>" + x.label + "</label><br>");
      metadataHtml += "</div>"

      if (this.typeOfAccess !== 'opendata') {
        metadataHtml += "<br>"
        metadataHtml += "<h4>Produkte für registrierte Vermessungsstellen</h4>"
        metadataHtml += "<div style='margin-left:10px'>"
        infoProducts_Vermessungsstelle.forEach((x) =>
            metadataHtml +=
                "<input type='checkbox' id='" + x.id + "' onclick='return false;'" + (props[x.prop] ? "checked=''" : "") + ">" +
                " <label for='" + x.id + "'>" + x.label + "</label><br>");
        metadataHtml += "</div><br>"
        metadataHtml += "<h4>Funktionen für registrierte Vermessungsstellen</h4>"
        metadataHtml += "<div style='margin-left:10px'>"
        infoFunctions_Vermessungsstelle.forEach((x) =>
            metadataHtml +=
                "<input type='checkbox' id='" + x.id + "' onclick='return false;'" + (props[x.prop] ? "checked=''" : "") + ">" +
                " <label for='" + x.id + "'>" + x.label + "</label><br>");
        metadataHtml += "</div>"
      }
      this.metadata = metadataHtml;
    }
  }
  ,
  mounted() {
    const mapnrwinit = document.getElementById('itnrwMapScriptInit');

    if (!mapnrwinit) {
      const script = document.createElement('script');
      script.setAttribute('id', 'itnrwMapScriptInit');
      script.setAttribute('src', mapnrw);
      script.setAttribute('type', 'module');
      script.async = true;
      script.onload = () => {
        window.dispatchEvent(new CustomEvent('itnrw:reload-maps'));
      };
      document.head.appendChild(script);
    } else {
      window.dispatchEvent(new CustomEvent('itnrw:reload-maps'));
    }

    const patternCanvas_Wartung = document.createElement('canvas');
    const patternContext_Wartung = patternCanvas_Wartung.getContext('2d');

    patternCanvas_Wartung.width = 14;
    patternCanvas_Wartung.height = 14;

    // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Applying_styles_and_colors
    // https://developer.mozilla.org/en-US/play
    patternContext_Wartung.lineWidth = 1.8;
    patternContext_Wartung.beginPath();
    patternContext_Wartung.moveTo(0, 0);
    patternContext_Wartung.lineTo(14, 14);
    patternContext_Wartung.strokeStyle = 'rgb(0,72,255)'
    patternContext_Wartung.stroke();

    const canvas_Wartung = document.createElement('canvas');
    const ctx_Wartung = canvas_Wartung.getContext('2d');
    this.pattern_Wartung = ctx_Wartung.createPattern(patternCanvas_Wartung, 'repeat');

    const patternCanvas_Stoerung = document.createElement('canvas');
    const patternContext_Stoerung = patternCanvas_Stoerung.getContext('2d');

    patternCanvas_Stoerung.width = 14;
    patternCanvas_Stoerung.height = 14;
    patternContext_Stoerung.lineWidth = 1.8;
    patternContext_Stoerung.beginPath();
    patternContext_Stoerung.moveTo(0, 14);
    patternContext_Stoerung.lineTo(14, 0);
    patternContext_Stoerung.strokeStyle = 'rgb(255,0,0)'
    patternContext_Stoerung.stroke();

    const canvas_Stoerung = document.createElement('canvas');
    const ctx_Stoerung = canvas_Stoerung.getContext('2d');
    this.pattern_Stoerung = ctx_Stoerung.createPattern(patternCanvas_Stoerung, 'repeat');

    window.itnrwMapReady = (id, map) => {
      if (id === "itnrwMap_0") {
        map.addLayer(this.vectorLayer_Kreise);
        map.addLayer(this.vectorLayer_Verfuegbarkeit);
        map.addLayer(this.vectorLayer_Wartung);
        map.addLayer(this.vectorLayer_Stoerung);
        this.style_Wartung.getFill().setColor(this.pattern_Wartung)
        this.style_Stoerung.getFill().setColor(this.pattern_Stoerung)
      }
    };

    window.infoClick = (data) => {
      window.itnrwMaps[0].clear("search");

      let features = data.features;
      let coord = features.filter((feature) => feature.properties.description === 'MapPoint')[0].geometry.coordinates

      fetch(API.url + '/produkt/selectbycoord.json', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              coord[0],
              coord[1]
            ]
          },
          "crs": {
            "type": "name",
            "properties": {
              "name": "EPSG:25832"
            }
          }
        })
      })
          .then(x => x.text())
          .then(metadata => this.showMetadata(JSON.parse(metadata).features));
    };
  }
}
</script>